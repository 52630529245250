.breadcrumb {
    font-size: 0.8em;
    margin-left: 15px;


    .home {
        // color: $neutral-light;
        color: $link;
        margin-right: 5px;
    }

    .current {
        color: $textcolor;
        border-left: 0px;
        padding-left: 5px;
    }
}