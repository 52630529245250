
.fixed.compact .search-container.active {
    top: 66px !important;
}

.search-container {
    top: 105px;
    position: absolute;
    @media (min-width: 768px) and (max-width:991px) {
        top: 105px;
    
    }
    @media only screen and (max-width : 767px) {
        top: 5px !important;
    }
} 

.dv_searchbox {
    background: #f7f7f7;
    padding: 10px;
    width: 90%;
    margin: auto;
    border: 1px solid #e8e8e8;
    float: left;
}

.spn_searchBox input {
    background: transparent;
    border: 0px;
    font-size: 1.0em;
    color: #202020;
    width: 94%;
    line-height: 1.1em;
    margin-bottom: 10px;
    @media (min-width: 768px) and (max-width:991px) {
        width: 91%;
    }
    @media only screen and (max-width : 767px) {
        width: 88%;
    }

    &[type=text]:hover, &[type=text]:focus {
        border: 0;
        outline: 0;
    }
}

.spn_searchIcon a {
    font-size: 1.5em;
    color: $neutral-dark;
    text-decoration: none;
    
    @media only screen and (max-width : 767px) {
        font-size: 1.5em;
    }
}

.searchoriginalLocation .close {
    font-size: 1.5em;
    opacity: 1;
    margin-right: 49px;
    margin-top: 14px;
    @media (min-width: 768px) and (max-width:991px) {
        margin-right: 0;
    }
    @media only screen and (max-width : 767px) {
        margin-top: 0px;
        padding-top: 17px;
        float: right;
        text-align: center;
        margin-right: 2%;
    }
}