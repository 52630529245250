/*Sharepoint Overide*/
#s4-bodyContainer {
    padding-bottom: 0px;
}

#s4-titlerow, #sideNavBox {
    display: none !important;
}

#contentBox {
    min-width: 10px !important;
    width: 100% !important;
    margin: 0px auto;
}

#contentRow {
    padding-top: 0;
}

input[type=button], input[type=reset], input[type=submit], button {
    min-width: 1px;
}


#ctl00_ctl64_ExplicitLogin {
    display: none !important;
}

div.ms-srch-hover-action > a[clicktype='ActionEdit'] {
    display: none;
}

div.ms-srch-hover-action > a[id*='_hoverFollow'] {
    display: none;
}

div.ms-srch-hover-action > a[clicktype='ActionViewDupes'] {
    display: none;
}

div.ms-srch-hover-action > a[clicktype='ActionViewLibrary'] {
    display: none;
}

.ms-srch-item-path {
    display: none !important;
}

.ms-dlg-heading, h2.ms-dlg-heading {
    font-family: inherit;
    font-size: 1em;
}

.ms-srch-hover-subTitle .ms-soften {
    font-family: inherit;
    font-size: 1em;
}

.ms-srch-hover-text {
    font-size: 0.89em;
}

.ms-calloutLink:link {
    font-size: 0.89em;
}

.ms-signInLink {
    display: none !important;
}

.ms-ToolPaneOuter {
    // margin-top: 174px;
}


.layout-searchResult .ms-promlink-button {
    padding: 0
}

// accessibility
.ms-metadata, .ms-descriptiontext,
.ms-helperText, input.ms-helperText ,
.ms-soften, .ms-soften:link, a.ms-soften:visited, .ms-soften:hover, .ms-soften:active,
.ms-commandLink, .ms-commandLink:visited, .ms-commandGlyph, .ms-commandGlyph:visited,
.ms-emptyMode, .ms-attractMode,
.ms-attractMode,
.ms-rteTable-default > tbody > tr > th, .ms-rteTable-default > tbody > tr.ms-rteTableFooterRow-default, .ms-rteTable-0 > tbody > tr > th, .ms-rteTable-0 > tbody > tr.ms-rteTableFooterRow-0, .ms-rteTable-1 > tbody > tr > th, .ms-rteTable-1 > tbody > tr.ms-rteTableFooterRow-1, .ms-rteTable-2 > tbody > tr > th, .ms-rteTable-2 > tbody > tr.ms-rteTableFooterRow-2, .ms-rteTable-3 > tbody > tr > th, .ms-rteTable-3 > tbody > tr.ms-rteTableFooterRow-3, .ms-rteTable-4 > tbody > tr > th, .ms-rteTable-4 > tbody > tr.ms-rteTableFooterRow-4, .ms-rteTable-5 > tbody > tr > th, .ms-rteTable-5 > tbody > tr.ms-rteTableFooterRow-5, .ms-rteTable-6 > tbody > tr > th, .ms-rteTable-6 > tbody > tr.ms-rteTableFooterRow-6, .ms-rteTable-7 > tbody > tr > th, .ms-rteTable-7 > tbody > tr.ms-rteTableFooterRow-7, .ms-rteTable-8 > tbody > tr > th, .ms-rteTable-8 > tbody > tr.ms-rteTableFooterRow-8, .ms-rteTable-9 > tbody > tr > th, .ms-rteTable-9 > tbody > tr.ms-rteTableFooterRow-9, .ms-rteTable-10 > tbody > tr > th, .ms-rteTable-10 > tbody > tr.ms-rteTableFooterRow-10,
.ms-rteTable-default > tbody > tr > th, .ms-rteTable-default > tbody > tr.ms-rteTableFooterRow-default, .ms-rteTable-0 > tbody > tr > th, .ms-rteTable-0 > tbody > tr.ms-rteTableFooterRow-0, .ms-rteTable-1 > tbody > tr > th, .ms-rteTable-1 > tbody > tr.ms-rteTableFooterRow-1, .ms-rteTable-2 > tbody > tr > th, .ms-rteTable-2 > tbody > tr.ms-rteTableFooterRow-2, .ms-rteTable-3 > tbody > tr > th, .ms-rteTable-3 > tbody > tr.ms-rteTableFooterRow-3, .ms-rteTable-4 > tbody > tr > th, .ms-rteTable-4 > tbody > tr.ms-rteTableFooterRow-4, .ms-rteTable-5 > tbody > tr > th, .ms-rteTable-5 > tbody > tr.ms-rteTableFooterRow-5, .ms-rteTable-6 > tbody > tr > th, .ms-rteTable-6 > tbody > tr.ms-rteTableFooterRow-6, .ms-rteTable-7 > tbody > tr > th, .ms-rteTable-7 > tbody > tr.ms-rteTableFooterRow-7, .ms-rteTable-8 > tbody > tr > th, .ms-rteTable-8 > tbody > tr.ms-rteTableFooterRow-8, .ms-rteTable-9 > tbody > tr > th, .ms-rteTable-9 > tbody > tr.ms-rteTableFooterRow-9, .ms-rteTable-10 > tbody > tr > th, .ms-rteTable-10 > tbody > tr.ms-rteTableFooterRow-10,
.ms-rtestate-field h4, h4.ms-rteElement-H4,
.ms-rteStyle-References, .ms-rteStyle-IntenseReferences,
.ms-rteStyle-Tagline,
.ms-picker-table .ms-picker-dayheader,
.ms-picker-dayother a:link,
.ms-picker-monthcenter a,.ms-picker-monthcenter a:active,.ms-picker-monthcenter a:link,.ms-picker-monthcenter a:visited,
.UserSectionHead,
.ms-SPZoneLabel,
.ms-SPZoneTitle,
.ms-SPZoneCaption,
.ms-webpart-zone-title,
.ms-comm-ratingCountContainer ,
.ms-comm-ratingSeparator,.ms-navedit-hiddenLink.ms-core-listMenu-item, .ms-navedit-hiddenLink.ms-core-listMenu-item:link, .ms-navedit-hiddenLink.ms-core-listMenu-item:visited,
.ms-core-header .ms-navedit-hiddenLink.ms-core-listMenu-item, .ms-core-header .ms-navedit-hiddenLink.ms-core-listMenu-item:link, .ms-core-header .ms-navedit-hiddenLink.ms-core-listMenu-item:visited,
.ms-spgrid-col-header-normal,
.ms-list-emptyText,
.ms-list-emptyText-compact,
.ms-InlineSearch-SearchBox-EmptyUnfocused.ms-InlineSearch-SearchBox-EmptyUnfocused,
.ms-attractMode-Text {
    color: $textcolor;
}

a:focus.ms-SkiptoNavigation, 
a:focus.ms-SkiptoMainContent,
#linkTurnOnAcc:focus {
    position: absolute;
    top: 2px;
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
    padding: 2px 4px;
    z-index: 12;
    height: auto;
    width: auto;
}