.missionStatement {
    color: #5e5e5e;
    font-size: 1.33em;
    text-align: center;
    margin: 12px auto 31px auto;
    line-height: 30px;
}


.signUpContainer {
    margin-bottom: 30px;
}


.modelCountingContainer {
    /*border: 1px solid #ccc;*/
    margin-bottom: 25px;
    /*background: #323A45;*/
    background: transparent;
}


.findAssitanceBox .customImgRes {
    width: 100%;
    max-width: 172px;
    display: block;
    height: auto;
}

.icon-assitance img {
    margin: auto
}

.icon-assitance:hover img {
    -webkit-box-shadow: 0px 0px 33px -3px rgba(19,19,4,1);
    -moz-box-shadow: 0px 0px 33px -3px rgba(19,19,4,1);
    box-shadow: 0px 0px 33px -3px rgba(19,19,4,1);
    border-radius: 50%;
    height: 100%;
}

.findAssitanceBox {
    width: 80%;
    margin: 0 auto 10px auto;
}

    .findAssitanceBox h1 {
        background: url(../images/iconFindAssistance.png) 0px 7px no-repeat;
        display: inline-block;
        padding: 9px 0 9px 80px;
        font-size: 2.22em;
        color: $primary-med;
        font-weight: 600;
        width: auto;
        margin: 0 auto 24px auto;
        line-height: 57px;
    }

    .findAssitanceBox a {
        font-size: 1em;
        color: $neutral-med;
        font-weight: 600;
        display: block;
        text-align: center;
    }

        .findAssitanceBox a:hover {
            color: $primary-bright;
            text-decoration: none;
        }

.icon-assitance .linkTxt {
    margin-top: 20px;
    margin-bottom: 20px;
}


.findLocalAgencyContainer {
    background: #f3f3f3;
    padding: 35px 0;
    margin-bottom: 50px;
    position: relative;
}

    .findLocalAgencyContainer h1 {
        color: #0177A2;
        font-size: 3.06em;
        margin: 0;
        text-align: center;
        font-weight: 600;
    }

    .findLocalAgencyContainer h2 {
        color: #262626;
        font-size: 1.67em;
        text-align: center;
        margin: 5px 0 0 0
    }

    .findLocalAgencyContainer .ms-rtestate-field h2 {
        color: #262626;
    }

    .findLocalAgencyContainer .formContent {
        /*width: 44%;
        margin: 15px auto 25px 350px*/ padding: 0;
        margin-top: 20px;
    }



        .findLocalAgencyContainer .formContent .textField {
            /*float: none;*/
        }

        .findLocalAgencyContainer .formContent .buttonField {
            float: left;
            display: none;
        }

        .findLocalAgencyContainer .formContent .textField [type="text"] {
            border: 1px solid #bbb;
            padding: 10px 30px;
            background-color: #fff !important;
            width: 100%;
        }

        .findLocalAgencyContainer .formContent .buttonField [type="button"] {
            background: $neutral-dark;
            font-size: 1.22em;
            font-weight: 600;
            padding: 13px 30px;
            border: 0;
            color: #fff;
            margin-left: 12px;
        }

            .findLocalAgencyContainer .formContent .buttonField [type="button"]:hover {
                background: $accent;
                color: $neutral-dark;
            }

    .findLocalAgencyContainer .sepration {
        font-size: 1.78em;
        color: #111111;
        margin: 0px 0 18px 0;
        text-align: center
    }

    .findLocalAgencyContainer .mapIt {
        text-align: center
    }

    
.sucessBoxContainer {
    margin-bottom: 30px
}

    .sucessBoxContainer h1 {
        background: url(../images/iconEnjoySucess.png) 0px 7px no-repeat;
        display: inline-block;
        padding: 9px 0 9px 80px;
        font-size: 2.22em;
        color: $primary-med;
        font-weight: 600;
        width: auto;
        margin: 0 auto 33px auto;
        line-height: 57px;
    }

.sucessInsideContainer {
    position: relative;
}

    .sucessInsideContainer .imgContainer img {
        width: 100%;
        max-width: 400px;
        display: block;
        height: auto;
    }

.sucessInsideContainer {
    position: relative;
}

    .sucessInsideContainer .sucessMsg {
        background: #e1f2f7;
        border-left: 1px solid #c4e7f4;
        border-bottom: 1px solid #c4e7f4;
        border-right: 1px solid #c4e7f4;
        padding: 10px;
        font-size: 0.78em
    }

    .sucessInsideContainer .sucessMsgTitle {
        font-weight: 600;
        margin-bottom: 5px;
    }

        .sucessInsideContainer .sucessMsgTitle a {
            color: $neutral-dark;
        }
/*video */
.sucessBoxContainer video {
    width: 100% !important;
    max-width: 400px;
    display: block;
    height: 338px;
}

.sucessBoxContainer .videocontain {
    position: relative;
    /*padding-bottom: 46%;*/
    height: 340px;
    overflow: hidden;
}

    .sucessBoxContainer .videocontain iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

/*video */

/*Enjoy our Successes*/

/*Explore Programe*/

.exploreProgrameContainer {
    margin-bottom: 20px
}

    .exploreProgrameContainer h1 {
        background: url(../images/iconExplore.png) 0px 7px no-repeat;
        display: inline-block;
        padding: 9px 0 9px 80px;
        font-size: 2.22em;
        color: $primary-med;
        font-weight: 600;
        width: auto;
        margin: 0 auto 33px auto;
        line-height: 57px;
    }

.exploreProgramOuter {
}

    .exploreProgramOuter .paddingLeft0 {
        padding-left: 0;
    }

    .exploreProgramOuter .paddingRight0 {
        padding-right: 0;
    }

    .exploreProgramOuter .text-grid {
        background: #f2f2f2;
        min-height: 432px;
        padding: 30px
    }

        .exploreProgramOuter .text-grid .btn {
            font-size: 1.11em;
            background: #fff;
        }

        .exploreProgramOuter .text-grid .btn-default:visited {
            color: #000;
        }

        .exploreProgramOuter .text-grid .btn-default:hover {
            background: #0073A3;
            color: #fff;
            border: 1px solid #006691
        }

    .exploreProgramOuter .blueColGrid {
        /*background: #d8eaf4;
        min-height: 404px;
        padding: 30px*/
    }

    .exploreProgramOuter .orangeColGrid {
        /*background: #fee5a7;
        min-height: 404px;
        padding: 30px*/
    }

    .exploreProgramOuter .greenColGrid {
        /*background: #a8d0a4;
        min-height: 404px;
        padding: 30px*/
    }

    .exploreProgramOuter .blueColGrid img {
        width: 100%;
        max-width: 800px;
        height: auto;
    }

    .exploreProgramOuter .orangeColGrid img {
        width: 100%;
        max-width: 800px;
        height: auto;
    }

    .exploreProgramOuter .greenColGrid img {
        width: 100%;
        max-width: 800px;
        height: auto;
    }

    .exploreProgramOuter .text-grid h2 {
        color: $neutral-dark;
        font-size: 2.78em;
        margin: 0;
        font-weight: 600;
        line-height: 48px;
    }

    .exploreProgramOuter .text-grid p {
        color: #4c4c4c;
        font-size: 1.11em;
        line-height: 30px;
        margin: 20px 0;
    }

.buttonBlock {
    margin-top: 40px;
    text-align: center
}

    .buttonBlock .btn {
        font-size: 1.33em;
    }

    .buttonBlock .btn-standout {
        background: $neutral-med;
    }

        .buttonBlock .btn-standout:hover {
            background: #363636;
        }

    .buttonBlock a.btn-standout {
        color: #fff !important;
    }

        .buttonBlock a.btn-standout:visited {
            color: #fff !important;
        }


/*Programs Section*/
.layout-programs .ms-webpartzone-cell {
    margin: auto auto 0px;
}

.exploreProgramInnerPageOuter {
    margin-bottom: 40px;
}

    .exploreProgramInnerPageOuter .text-grid {
        background: #f2f2f2;
        min-height: 432px;
        padding: 30px;
        border-bottom: 1px solid #c4c4c4;
    }

        .exploreProgramInnerPageOuter .text-grid .btn {
            font-size: 1.11em;
        }

        .exploreProgramInnerPageOuter .text-grid .btn-default:visited {
            color: #000;
        }

        .exploreProgramInnerPageOuter .text-grid .btn-default:hover {
            background: #0073A3;
            color: #fff;
            border: 1px solid #006691
        }


    .exploreProgramInnerPageOuter .blueColGrid {
        /*background:#d8eaf4;
  min-height:404px;
   padding:30px*/
    }

    .exploreProgramInnerPageOuter .orangeColGrid {
        /* background:#fee5a7;min-height:404px;
   
    padding:30px*/
    }

    .exploreProgramInnerPageOuter .greenColGrid {
        /*background:#a8d0a4;min-height:404px;  padding:30px*/
    }

    .exploreProgramInnerPageOuter .redColGrid {
        /*background:#eeb0a4;min-height:404px;  padding:30px*/
    }

    .exploreProgramInnerPageOuter .blueColGrid img {
        width: 100%;
        max-width: 800px;
        height: auto;
    }

    .exploreProgramInnerPageOuter .orangeColGrid img {
        width: 100%;
        max-width: 800px;
        height: auto;
    }

    .exploreProgramInnerPageOuter .greenColGrid img {
        width: 100%;
        max-width: 800px;
        height: auto;
    }

    .exploreProgramInnerPageOuter .redColGrid img {
        width: 100%;
        max-width: 800px;
        height: auto;
    }


    .exploreProgramInnerPageOuter .text-grid h2 {
        color: $neutral-dark;
        font-size: 2.78em;
        margin: 0;
        font-weight: 600;
    }

    .exploreProgramInnerPageOuter .text-grid p {
        color: #4c4c4c;
        font-size: 1.11em;
        line-height: 30px;
        margin: 20px 0;
    }

/*Programs Section*/

/*assistance Section*/
.assistanceBoxRepeat {
    border-bottom: 1px solid #f5872a;
    width: 90%;
    margin: 0 auto 40px auto;
}

/*.assistanceBoxRepeat:last-of-type {
        border-bottom: 0px;
    }*/

.assistanceTitle {
    font-size: 1.67em;
    color: #000;
    font-weight: 600;
    text-align: center;
    margin-bottom: 27px;
}

.assistanceDetailsOuter .iconContainer {
    margin-bottom: 27px;
}

    .assistanceDetailsOuter .iconContainer img {
        margin: auto
    }

.assistanceDetailsOuter .customImgRes {
    width: 100%;
    max-width: 172px;
    display: block;
    height: auto;
}

.assistanceDetailsOuter .assistanceText {
    color: #585858;
    font-size: 1.22em;
    line-height: 30px;
    margin-bottom: 40px;
}

    .assistanceDetailsOuter .assistanceText.textCenter {
        text-align: center
    }

.assistanceDetailsOuter .buttonContainerCenter {
    text-align: center;
    margin-bottom: 35px;
}

    .assistanceDetailsOuter .buttonContainerCenter a.btn {
        font-size: 1.11em;
    }

        .assistanceDetailsOuter .buttonContainerCenter a.btn:hover {
            background: #0073A3;
            color: #fff;
            border: 1px solid #006691
        }


.additionalAsstContainer {
}

    .additionalAsstContainer .titleInfo {
        color: #212121;
        font-size: 1.22em;
        line-height: 30px;
        margin-bottom: 20px;
    }

    .additionalAsstContainer .additionLinkContainer {
        margin-bottom: 60px;
    }

        .additionalAsstContainer .additionLinkContainer a {
            font-size: 1.33em;
            color: #0699cc;
            line-height: 30px;
        }

    .additionalAsstContainer .buttonContainerCenter {
        text-align: center;
        margin-bottom: 35px;
    }

        .additionalAsstContainer .buttonContainerCenter a.btn {
            font-size: 1.11em;
        }

            .additionalAsstContainer .buttonContainerCenter a.btn:hover {
                background: #0073A3;
                color: #fff;
                border: 1px solid #006691;
            }
/*assistance Section*/

/*program Details*/
.leftSideContainer {
    border: 1px solid #babac9;
    padding: 4px;
}

.leftSideImage img {
    max-width: 400px;
    display: block;
    height: auto !important;
    float: left;
    margin-right: 15px !important;
    margin-bottom: 15px !important;
}



.leftSideImage {
    color: #585858;
    font-size: 1.17em;
    line-height: 28px;
}

.leftSideContainer .ui-accordion .ui-accordion-header {
    background: #f3852a;
    color: #fff;
    border: 0;
    padding: 11px 13px 9px 13px;
}

/*.leftSideContainer .ms-webpart-titleText.ms-webpart-titleText, .ms-webpart-titleText > a {
    color: #fff;
    font-family: inherit !important;
    font-size: 1.33em;
    line-height: 28px;
}*/

.leftSideContainer .ui-icon, .ui-widget-content .ui-icon {
    background-image: none !important;
}

.leftSideContainer .ui-state-active .ui-icon, .ui-button:active .ui-icon {
    background-image: none !important;
}

.leftSideContainer .ui-icon:before {
    content: "\45";
    font-family: "CaGov";
    line-height: 1;
    display: inline-block;
    color: white;
    margin: 2px 3px 2px 1px;
}

.leftSideContainer .ui-state-active .ui-icon:before {
    content: "\43";
    font-family: "CaGov";
    line-height: 1;
    display: inline-block;
    color: white;
    margin: 2px 3px 2px 1px;
}

.leftSideContainer .ui-icon {
    float: right;
    text-indent: 0px !important;
    margin-top: 1px !important;
    font-size: 1.33em;
    width: auto !important;
    height: auto !important;
}

.leftSideContainer .js-webpart-titleCell {
    width: auto;
}

.leftSideContainer .ui-accordion .ui-accordion-content {
    background: #f0f0f0;
    padding: 10px;
    font-size: 1em;
    color: #585858;
}

.leftSideContainer .ui-widget-content {
    border: 1px solid #ececfb;
}

.leftSideContainer .ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
    border-radius: 0 !important;
}

.leftSideContainer .ui-widget {
    font-family: 'Source Sans Pro', sans-serif;
}

.multiStepContainer {
    margin-top: 4px;
}

    .multiStepContainer .panel-default > .panel-heading {
        background: #f3852a;
        color: #fff;
        border: 0;
    }

    .multiStepContainer .panel {
        border: 0;
    }

    .multiStepContainer .panel-default .panel-body {
        background: #f0f0f0;
    }

    .multiStepContainer .panel-group {
        margin-bottom: 0;
    }

        .multiStepContainer .panel-group .panel {
            margin-bottom: 0 !important;
            margin-top: 4px;
        }

        .multiStepContainer .panel-group .panel-heading .panel-title {
            font-size: 1.33em;
            line-height: 28px;
        }

        .multiStepContainer .panel-group .panel-heading a {
            padding-left: 9px;
            padding-top: 13px;
            padding-bottom: 13px;
            padding-right: 13px
        }

        .multiStepContainer .panel-group .panel-heading .panel-title a:hover, .multiStepContainer .panel-group .panel-heading .panel-title a:focus {
            background: #f3852a;
            color: #fff;
        }

    .multiStepContainer .panel-heading .panel-title a:after {
        color: #fff;
    }

    .multiStepContainer .panel-collapse > .panel-body {
        border-top: 0px !important;
    }

    .multiStepContainer .panel-body {
        padding: 10px;
        line-height: 25px;
    }

.boxContentContainer {
    margin-bottom: 17px;
}

    .boxContentContainer h3 {
        font-size: 1.33em;
        line-height: 30px;
        font-weight: 600;
        border-bottom: 2px solid $accent;
        background: #1891c5;
        display: block;
        margin: 0;
        color: #fff;
        padding: 12px;
    }

    .boxContentContainer .boxPanelBody {
        border: 1px solid #babac8;
        padding: 14px;
        font-size: 1em;
    }

        .boxContentContainer .boxPanelBody a {
            color: #0071bc;
        }

    .boxContentContainer .boxPanelBodyList {
        border: 1px solid #babac8;
        padding: 14px;
        font-size: 1em;
    }

        .boxContentContainer .boxPanelBodyList a {
            color: #0071bc;
            background: url(../images/iconArrow.png) no-repeat;
            display: block;
            margin: 12px 0;
            padding: 0 0 0 33px;
        }

.programeHighlightsContainer {
    margin-top: 34px;
}

    .programeHighlightsContainer h1 {
        color: $primary-med;
        font-size: 2.33em;
        line-height: 57px;
        margin: 0 0 34px 0;
        font-weight: 600;
        text-align: center
    }

    .programeHighlightsContainer .borderBox {
        position: relative;
        border: 1px solid #babac9;
        padding: 4px;
    }

    .programeHighlightsContainer .captionContainer {
        background: $primary-med;
        padding: 16px 0;
        text-align: center;
        font-size: 2em;
        color: #fff;
        display: block;
    }

        .programeHighlightsContainer .captionContainer a {
            color: #fff;
            text-decoration: none;
        }

            .programeHighlightsContainer .captionContainer a:visited {
                color: #fff;
            }

    .programeHighlightsContainer .customImgRes {
        width: 100% !important;
        max-width: 600px;
        display: block;
        height: auto !important;
    }

    .programeHighlightsContainer .marginBottom50 {
        margin-bottom: 50px;
    }

    .programeHighlightsContainer .titleBox {
        color: $primary-med;
        font-size: 1.33em;
        text-align: center;
        margin-bottom: 20px;
    }

.boxPanelBody img {
    width: 100% !important;
    display: block;
    height: auto !important;
}
/*program Details*/

/*Stay in touch*/
.contactInformation .contactLabel {
    font-size: 1.33em;
    color: #595959;
}

.contactInformation .details {
    font-size: 1em;
    color: #212121;
    line-height: 25px;
    margin-bottom: 30px;
}

.signUpContainer .signUpTitle {
    color: $primary-med;
    font-size: 2.78em;
    font-weight: bold;
    padding: 10px 0 20px 0;
}

.signUpContainer .signUpInfo {
    color: #555;
    font-size: 1em;
    margin-left: 10px;
    margin-bottom: 35px;
    display: inline-block
}

.signUpContainer .form-control {
    border: 1px solid #99ccff;
}

.signUpContainer .labText {
    font-size: 1.33em;
    color: #555;
    font-weight: normal
}

.signUpContainer .distributionList {
    margin: 30px 0
}

    .signUpContainer .distributionList .distTitle {
        font-size: 1.33em;
        color: #555;
    }

    .signUpContainer .distributionList .checkbox label {
        font-size: 1.11em;
        color: #545454;
        padding-left: inherit;
    }

    .signUpContainer .distributionList input[type="checkbox"] {
        margin-top: 7px;
    }

    .signUpContainer .distributionList .distributionOptions {
        margin-left: 30px;
        margin-bottom: 30px;
    }

    .signUpContainer .distributionList .captchaContainer {
        margin-bottom: 30px
    }

    .signUpContainer .distributionList .btn {
        font-size: 1.11em;
        font-family: inherit;
        margin-left: 0;
    }

    .signUpContainer .distributionList .btn-default:visited {
        color: #000;
    }

    .signUpContainer .distributionList .btn-default:hover {
        background: #0073A3;
        color: #fff;
        border: 1px solid #006691
    }

@media (min-width: 768px) and (max-width:991px) {
    .iconPrograms {
        display: block
    }

    .findLocalAgencyContainer .formContent {
        width: 70%;
    }

        .findLocalAgencyContainer .formContent.customClass {
            margin-top: 20px;
        }
    
    .missionStatement {
        font-size: 1.11em;
        /*width: 80%;*/
        margin: 10px auto 10px auto;
    }

    .programeHighlightsContainer .captionContainer {
        font-size: 1.3em;
    }

    .programeHighlightsContainer .marginBottom50 {
        margin-bottom: 25px;
    }

    .titleInnerPage {
        font-size: 1.94em;
    }

    .exploreProgramInnerPageOuter .paddingLeft2 {
        padding-left: 0px;
    }

    .exploreProgramInnerPageOuter .paddingRight2 {
        padding-right: 0px;
    }

    .exploreProgramInnerPageOuter .text-grid h2 {
        font-size: 1.58em;
        line-height: 36px;
    }

    .exploreProgramInnerPageOuter .text-grid {
        padding: 10px;
        min-height: 249px;
        overflow: hidden;
    }

        .exploreProgramInnerPageOuter .text-grid p {
            font-size: 0.89em;
            line-height: 21px;
            margin: 0px 0 10px 0;
        }

        .exploreProgramInnerPageOuter .text-grid .btn {
            font-size: 0.89em;
            padding: 3px 5px;
        }

    .exploreProgramOuter .text-grid h2 {
        font-size: 1.58em;
        line-height: 36px;
    }

    .exploreProgramOuter .text-grid {
        padding: 10px;
        min-height: 249px;
        overflow: hidden;
    }

        .exploreProgramOuter .text-grid p {
            font-size: 0.89em;
            line-height: 22px;
            margin: 0px 0 10px 0;
        }

        .exploreProgramOuter .text-grid .btn {
            font-size: 0.89em;
            padding: 3px 5px;
        }
    
        .padding-xssm0 {
            padding-left: 0
        }
    
        .leftSideImage .customImgRes {
            width: 100% !important;
            display: block;
            height: auto !important;
            float: none;
            margin-right: 0px;
            margin-bottom: 15px;
            max-width: initial;
        }
    
        .gtransHeight {
            height: 29px;
            overflow: hidden;
            position: inherit;
            left: 0;
            top: 0px;
        }

        .providerBlock {
            min-height: 285px;
        }
}



/*find agency*/
.suggestion-container {
    width: 100%;
    position: absolute;
    top: 45px;
    left: 0;
    z-index: 1;
}

    .suggestion-container table {
        width: 100%;
        border: 1px solid #ccc;
        background: $white;
    }

        .suggestion-container table tr:hover {
            background: #ffe1bb;
        }

        .suggestion-container table tr.active {
            background: #ffe1bb;
        }




        .suggestion-container table th, .suggestion-container table td {
            padding: 5px;
        }

        .suggestion-container table td {
            cursor: pointer;
            border-bottom: 0px solid #ccc;
            font-size: 0.9em;
        }

        .suggestion-container table th {
            background: $accent-darker;
            color: $white;
            padding: 5px;
        }

.mapHotspotContainer {
    text-align: center;
    position: relative;
    .suggestion-container {
        position: relative;
        top: 0px;
        width: 80%;
        left: 10%;

        table td {
            text-align: left;
        }
    }
}

.mapOverlaySearch {
    position: absolute;
    z-index: 1;
    width: 440px;
    height: 185px;
    right: 14px;
    top: 100px;
    background: #f3f3f3;
    padding: 10px;
    @media only screen and (max-width: 767px) {
        position: static;
        margin-bottom: 20px;
        width: 100%;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 350px;
    }
}

    .mapOverlaySearch h1 {
        color: #0177A2;
        font-size: 1.75em;
        margin: 0;
        margin-top: 20px;
        text-align: center;
        font-weight: 600;
        @media only screen and (min-width: 768px) and (max-width: 991px) {
            font-size: 1.5em;
            
        }
    }

    .mapOverlaySearch h2 {
        color: #262626;
        font-size: 1.2em;
        text-align: center;
        margin: 5px 0 10px 0;
    }

    .mapOverlaySearch .textField [type="text"] {
        border: 1px solid #bbb;
        padding: 6px 15px;
        background-color: $white !important;
        width: 80%;
        margin: 0px auto;
    }



.pdbt20 {
    padding-bottom: 20px;
}

.ms-rteFontFace-custom {
    -ms-name: "Source Sans Pro";
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    line-height: normal;
    background: $white;
}

.near-me {
    padding: 5px;
    padding-left: 40px;
    background: url(../images/near.png) no-repeat 10px 5px #c1c1c1;
    background-size: 24px;
}

    .near-me a {
        color: #000;
        cursor: pointer;
    }

.map-it {
    padding: 5px;
    padding-left: 40px;
    background: url(../images/map.png) no-repeat 10px 5px #c1c1c1;
    background-size: 24px;
    display: block;
    border-top: 0;
    border: 1px solid #CCC;
}

    .map-it a {
        color: #000;
    }




/*========detail page Css Start from here=================*/

.mapContainer {
    overflow: hidden;
    height: auto;
    min-height: 300px;
    @media only screen and (max-width : 767px) {
        margin-bottom: 15px;
    }
}

.providerinfo {
    border-left: 10px solid #f15153;
    padding-left: 15px;
}

    .providerinfo h2 {
        font-size: 1.2em;
    }

.contactInfoHeading {
    background: $accent2;
    padding: 7px;
    color: $white !important;
    margin-top: 10px;
}

.contactCon {
    border-bottom: 1px solid #000;
    padding-bottom: 15px;
    padding-top: 10px;
}

.ServiceContainer {
    padding: 15px 0;
}


.serviceCheckList {
    margin: 0;
    padding: 0;
}

    .serviceCheckList li {
        list-style: none;
        line-height: 40px;
        padding-left: 20px;
        position: relative;
    }

        .serviceCheckList li:before {
            content: "\f00c";
            font-family: FontAwesome;
            padding-right: 5px;
            position: absolute;
            padding-left: 0;
            left: -2px;
        }

.yellowHeading {
    color: #f68338;
    margin-top: 15px;
}

.yelloBdr {
    border-right: 1px solid #f68338;
}

.pdbt20 {
    padding-bottom: 20px;
}

/*===========four column css start here========*/
.indexUL {
    list-style: none;
    margin: 0;
    padding: 0;
    /*    overflow-y:auto;
    overflow-x:hidden;*/
}

    .indexUL li {
        width: 20%;
        float: left;
    }


    .indexUL a {
        text-decoration: underline;
        line-height: 30px;
    }

        .indexUL a:hover {
            text-decoration: none;
        }

/*===========four column css end here========*/

/*===========modal css start here========*/


.accordianContainer {
    margin-top: 15px;
}

    .accordianContainer .panel-default {
        display: block !important;
        border: 0 !important;
    }

        .accordianContainer .panel-default > .panel-heading {
            color: $neutral-dark;
            background-color: transparent !important;
            border-color: transparent !important;
        }

    .accordianContainer .panel-heading .panel-title a:hover, .accordianContainer .panel-heading .panel-title a:focus {
        background-color: transparent !important;
    }

    .accordianContainer .panel-title {
        font-size: 1em !important;
    }


    .accordianContainer .panel-collapse.collapse.in .panel-body {
        border-top: 0;
        border-top-color: $white;
    }

.panel-group .panel-heading + .panel-collapse > .panel-body {
    border-top: 0px solid #ddd;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: $white;
}


.panel-group .panel + .panel {
    margin-top: 0 !important;
}


.accordianContainer .panel-title a {
    padding: 5px;
    font-size: 1em;
}


.accordianContainer .panel-heading .panel-title a {
    padding-left: 20px;
}

    .accordianContainer .panel-heading .panel-title a:after {
        content: "\43";
        font-family: 'CaGov';
        position: absolute;
        right: inherit;
        top: 50%;
        margin-top: -11px;
        color: #777777;
        left: 0;
    }



    .accordianContainer .panel-heading .panel-title a.collapsed:after {
        content: "\45";
    }

.accordianContainer .panel-body {
    padding: 10px 20px;
}



.checkList {
    margin: 0;
    padding: 0 0 0 22px;
}




.providerinfo.csd-Red {
    border: 0px solid #ee5252;
    border-left: 10px solid #ee5252;
}

.providerinfo.csd-Blue {
    border: 0px solid #99e2fe;
    border-left: 10px solid #99e2fe;
}

.providerinfo.csd-Green {
    border: 0px solid #aee163;
    border-left: 10px solid #aee163;
}

.providerinfo.csd-Purple {
    border: 0px solid #9ea0f5;
    border-left: 10px solid #9ea0f5;
}

.providerinfo.csd-Yellow {
    border: 0px solid #ffd44e;
    border-left: 10px solid #ffd44e;
}




.programeDetailsContainer .multiStepContainer .panel-group .panel {
    margin-bottom: 5px !important;
}






/*===========modal css end here========*/


@media (min-width: 992px) and (max-width : 1280px) {
   

    .findLocalAgencyContainer .formContent {
    }

    .programeHighlightsContainer .captionContainer {
        font-size: 1.3em;
    }

    .dv_searchbox {
        width: 87%;
    }

    .exploreProgramInnerPageOuter .text-grid .btn {
        font-size: 0.89em;
        padding: 3px 5px;
    }

    .exploreProgramInnerPageOuter .text-grid h2 {
        font-size: 1.58em;
        line-height: 36px;
    }

    .exploreProgramInnerPageOuter .text-grid {
        padding: 10px;
        min-height: 325px;
        overflow: hidden;
    }

        .exploreProgramInnerPageOuter .text-grid p {
            font-size: 0.89em;
            line-height: 22px;
            margin: 0px 0 10px 0;
        }

    .exploreProgramOuter .text-grid h2 {
        font-size: 1.58em;
        line-height: 36px;
    }

    .exploreProgramOuter .text-grid {
        padding: 10px;
        min-height: 325px;
        overflow: hidden;
    }

        .exploreProgramOuter .text-grid p {
            font-size: 0.89em;
            line-height: 22px;
            margin: 0px 0 10px 0;
        }

        .exploreProgramOuter .text-grid .btn {
            font-size: 0.89em;
            padding: 3px 5px;
        }


}

/* Small Devices, Tablets */
@media only screen and (max-width : 767px) {

    .mobileTextCenter {
        text-align: center
    }

    .mobile-control {
        color: #62a9d1;
        font-size: 0.89em;
        background-color: transparent !important;
        border: none;
    }

    .findAssitanceBox {
        margin-bottom: 0px;
        width: 100%;
    }

        .findAssitanceBox h1 {
            background-size: 32px 32px;
            font-size: 1.67em;
            padding: 0px 0 0px 49px;
            width: auto;
            margin: 0 auto 27px auto;
            line-height: 47px;
        }

        .findAssitanceBox a {
            font-size: 1.33em;
            color: #222222;
            font-weight: 600;
            display: block;
            text-align: center;
            border-top: 1px solid #dfdfdf;
            padding: 30px 0
        }

    .icon-assitance:hover img {
        -webkit-box-shadow: 0px 0px 0px 0px;
        -moz-box-shadow: 0px 0px 0px 0px;
        box-shadow: 0px 0px 0px 0px;
    }

    .findAssitanceBox a:hover {
        color: #222222;
        text-decoration: none;
        background: #eeeeee;
        padding: 30px 0;
        outline: none;
    }

    .icon-assitance .linkTxt {
        margin-top: 10px;
        margin-bottom: 0px;
    }


    .findLocalAgencyContainer {
        padding: 20px 0;
        margin-top: 20px;
        margin-bottom: 20px;
    }

        .findLocalAgencyContainer h1 {
            font-size: 2.22em;
            margin: 0;
            text-align: center;
            font-weight: 600;
            margin-top: 0
        }

        .findLocalAgencyContainer h2 {
            font-size: 1.33em;
            margin: 10px 0 0 0
        }

        .findLocalAgencyContainer .formContent {
            width: 100%;
            margin: 15px auto 0px auto
        }

            .findLocalAgencyContainer .formContent .textField {
                float: inherit;
            }

            .findLocalAgencyContainer .formContent .buttonField {
                float: inherit;
            }

            .findLocalAgencyContainer .formContent .textField [type="text"] {
                border: 1px solid #bbb;
                padding: 15px 30px;
                width: 100%
            }

            .findLocalAgencyContainer .formContent .buttonField [type="button"] {
                padding: 13px 30px;
                border: 0;
                color: $white;
                margin-left: 0px;
                margin-top: 12px;
                width: 100%
            }

        .findLocalAgencyContainer .sepration {
            font-size: 1.78em;
            color: #111111;
            margin: 0px 0 18px 0;
            text-align: center
        }

        .findLocalAgencyContainer .mapIt {
            text-align: center
        }

    .sucessBoxContainer {
        margin-bottom: 0px
    }

        .sucessBoxContainer .ms-webpartzone-cell, .findAssitanceBox .ms-webpartzone-cell, exploreProgrameContainer .ms-webpartzone-cell {
            margin: auto auto 0px;
        }

        .sucessBoxContainer h1 {
            background-size: 32px 32px;
            font-size: 1.67em;
            padding: 0px 0 0px 49px;
            font-weight: 600;
            width: auto;
            margin: 0 auto 15px auto
        }

    .sucessInsideContainer {
        position: relative;
    }

        .sucessInsideContainer .imgContainer img {
            width: 100%;
            max-width: 400px;
            display: block;
            height: auto;
            margin: auto;
        }

    .sucessInsideContainer {
        position: relative;
        margin-bottom: 20px
    }

        .sucessInsideContainer .sucessMsg {
            background: #e1f2f7;
            border-left: 1px solid #c4e7f4;
            border-bottom: 1px solid #c4e7f4;
            border-right: 1px solid #c4e7f4;
            padding: 10px 23px;
            font-size: 0.78em;
        }

    .exploreProgrameContainer {
        margin-bottom: 50px
    }

        .exploreProgrameContainer h1 {
            background-size: 32px 32px;
            font-size: 1.67em;
            padding: 0px 0 0px 49px;
            width: auto;
            margin: 0 auto 15px auto
        }

    .exploreProgramOuter .text-grid {
        min-height: inherit;
        background: transparent;
    }

    .exploreProgramOuter .blueColGrid {
        min-height: inherit
    }

    .exploreProgramOuter .orangeColGrid {
        min-height: inherit
    }

    .exploreProgramOuter .greenColGrid {
        min-height: inherit
    }

    .exploreProgramOuter .blueColGrid img {
        width: 100%;
        max-width: 450px;
        display: block;
        height: auto;
        margin: auto;
    }

    .exploreProgramOuter .orangeColGrid img {
        width: 100%;
        max-width: 450px;
        display: block;
        height: auto;
        margin: auto;
    }

    .exploreProgramOuter .greenColGrid img {
        width: 100%;
        max-width: 450px;
        display: block;
        height: auto;
        margin: auto;
    }

    .exploreProgramOuter .text-grid h2 {
        color: $neutral-dark;
        font-size: 2.78em;
        margin: 0;
        font-weight: 600;
    }

    .exploreProgramOuter .text-grid p {
        color: #4c4c4c;
        font-size: 1.11em;
        line-height: 30px;
        margin: 20px 0;
    }

    .buttonBlock {
        margin-top: 10px;
        text-align: center
    }


    .modelCountingContainer {
        margin-bottom: 60px;
    }

        .modelCountingContainer .fontSize {
            font-size: 1.39em;
        }

        .modelCountingContainer p {
            margin-bottom: 60px;
        }


    /*.dv_searchbox {
	width: 100%;
	float: none;
}*/
    
    


    #siteSettings .p-y {
        padding-top: 0 !important;
    }

    #siteSettings.site-settings button.close {
        font-size: 1.8em;
        position: absolute;
        right: 17px;
        top: 16px;
        color: $white;
        background: none;
    }

    .copyTxt, .socialIcons, .footerRightSide {
        text-align: center;
    }

    .exploreProgramInnerPageOuter .blueColGrid {
        min-height: inherit
    }

    .exploreProgramInnerPageOuter .orangeColGrid {
        min-height: inherit
    }

    .exploreProgramInnerPageOuter .greenColGrid {
        min-height: inherit
    }

    .exploreProgramInnerPageOuter .redColGrid {
        min-height: inherit
    }

    .missionStatement {
        width: 100%;
        margin: 12px 0;
    }

    .exploreProgramOuter .text-grid {
        padding: 15px 30px 30px 30px
    }

        .exploreProgramOuter .text-grid p {
            margin: 8px 0 20px 0;
        }

    

    .exploreProgramInnerPageOuter .blueColGrid {
        min-height: inherit
    }

    .exploreProgramInnerPageOuter .orangeColGrid {
        min-height: inherit
    }

    .exploreProgramInnerPageOuter .greenColGrid {
        min-height: inherit
    }

    .exploreProgramInnerPageOuter .redColGrid {
        min-height: inherit
    }

    .titleInnerPage {
        font-size: 2em;
    }

    .assistanceBoxRepeat {
        width: 100%;
    }

    ul.topMovedLinks {
        padding: 0;
        list-style-type: none;
    }

        ul.topMovedLinks li {
            border-bottom: 1px solid #E1F2F7;
            padding-left: 50px;
        }

            ul.topMovedLinks li a {
                color: #535353;
                font-weight: 600;
                padding: 18px 0;
                font-size: 1em;
                display: inline-block;
                outline: none;
            }

    .exploreProgramInnerPageOuter .text-grid {
        background: transparent;
    }

    .boxContentContainer {
        margin-bottom: 0;
        margin-top: 17px;
    }

    .programeHighlightsContainer .captionContainer {
        font-size: 1.39em;
    }

    .programeHighlightsContainer .borderBox {
        margin-bottom: 15px;
    }

    .programeHighlightsContainer .marginBottom50 {
        margin-bottom: 15px;
    }

    .programeHighlightsContainer .imgBox {
        margin-bottom: 15px;
    }


    .sucessBoxContainer video {
        width: 100% !important;
        display: block;
    }

    .suggestion-container {
        width: 100%;
        position: static;
        top: inherit;
        left: inherit;
        z-index: 1;
    }


    .indexUL li {
        width: 100%;
        float: none;
    }


    #myModal .modal-dialog {
        width: 100%;
    }

}

@media only screen and (min-width : 769px) {
    .breakLine {
        display: block;
    }

    .exploreProgramInnerPageOuter .row {
        margin-bottom: 0px;
    }

    .exploreProgramInnerPageOuter .paddingLeft2 {
        padding-left: 0px;
    }

    .exploreProgramInnerPageOuter .paddingRight2 {
        padding-right: 0px;
    }

    

    ul.topMovedLinks {
        padding: 0;
        list-style-type: none;
        margin: 0;
    }

   
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
}

