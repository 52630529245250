
.allProviderCon {
    margin-top: 0;
}

.providerBlock {
    border: 1px solid #ccc;
    border-left: 30px solid #ccc;
    padding: 10px;
    min-height: 218px;
    margin-bottom: 30px;
    position: relative;
    padding-bottom: 35px;
}

.more {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 0.9em;
}

.providerName {
    font-size: 1.1em;
}

.addressCon, .info, .providerType {
    font-size: 0.8em;
}

.providerType {
    font-size: 0.8em;
    margin-top: 5px;
}

.csd-Red {
    border: 1px solid #ee5252;
    border-left: 30px solid #ee5252;
}

.csd-Blue {
    border: 1px solid #99e2fe;
    border-left: 30px solid #99e2fe;
}

.csd-Green {
    border: 1px solid #aee163;
    border-left: 30px solid #aee163;
}

.csd-Purple {
    border: 1px solid #9ea0f5;
    border-left: 30px solid #9ea0f5;
}

.csd-Yellow {
    border: 1px solid #ffd44e;
    border-left: 30px solid #ffd44e;
}