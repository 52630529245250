// colors
$primary: #1D7EAF;
$primary-dark: #045981;
$primary-med: #137eac;
$primary-brighter: #006dd9;
$primary-bright: #1697c8;
$accent: #ffca4a;
$accent-bright: #ffbf00;
$accent-dark: #FDB81E;
$accent-darker: #FF9D1E;
$accent2: #C35309;
$white: #fff;
$neutral-lighter: #f8f8f8;
$neutral-light: #adafb5;
$neutral-medlight: #666666;
$neutral-med: #5e5e5e;
$neutral-dark: #333;
$black: #000;
$border: #cccccc;
$link: $primary-dark;
$textcolor: $neutral-dark;
