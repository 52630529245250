.global-footer {
    background: $neutral-medlight;
    padding: 0 10px;
    ul {
        margin: 0;
    }

    a:not(.btn) {
        color: $white !important;
    }

    .firstCol {
        margin: 9px 0;
        .linkimg2 {
            margin-top: 10px;
        }
    }

    .thirdCol {
        margin: 9px 0;
        @media only screen and (max-width : 767px) {
            margin: 0px 0 10px 0;
        }
    }

    .footerLinks {
        margin-top: 6px;
        ul li {
            display: block;
            padding: 4px 0;
            a:not(.btn):hover {
                color: $accent-dark !important;
                text-decoration: none;
            }

            @media only screen and (max-width : 767px) {
                width: 100%;
                float: none;
            }
        }
    }

    .footerLinkBox {
        margin-bottom: 10px;
        background: $neutral-lighter;
        padding: 0 0 0 5px;
    }

    .footerLnkboxImg {
        float: left;
        width: 30%;
        height: 70px;
        background: $neutral-lighter;
        img {
            height: 60px;
        }
        @media only screen and (max-width : 767px) {
            width: 20%;
            IMG {
                height: 66px;
            }
        }
    }

    .footerLnkboxTxt {
        float: right;
        min-height: 70px;
        padding-left: 5px;
        padding-top: 5px;
        background: $neutral-lighter url(../images/footerImgBG.png) top right no-repeat;
        background-size: cover;
        width: 68%;
        @media only screen and (max-width : 767px) {
            width: 78%;
        }

        .text1 {
            font-size: 0.67em;
            color: $black;
            display: block;
            font-weight: 600;
        }

        .text2 {
            font-size: 1.11em;
            color: $black;
            display: block;
            font-weight: 600;

            @media (min-width: 768px) and (max-width:991px) {
                font-size: 0.89em;
            }

            @media (min-width: 992px) and (max-width : 1280px) {
                font-size: 0.89em;
            }
        }

        .link a {
            font-size: 0.67em;
            color: $primary-brighter !important;
            font-weight: 600;
            &:hover {
                color: $accent-dark !important;
            }
        }

    }

    .thirdCol .customImgRes img {
        width: 100%;
        max-width: 430px;
        display: block;
        height: auto;
    }
}


.copyright {
    background: $neutral-dark;
    color: $white;
    padding: 5px 0;


    .copyTxt {
        font-size: 0.78em;
        color: $white;
    }

    .socialIcons {
        text-align: center;

        a {
            margin-right: 10px;
            color: $white !important;

            &:not(.btn) {
                color: $white !important;

                &:hover {
                    color: $accent-bright !important;
                    text-decoration: none;
                }
            }
        }
    }

    .footerRightSide {
        font-size: 0.78em;
        color: $white;
        text-align: right;
        a {
            color: $white !important;
            &:not(.btn):hover {
                color: $accent-bright !important;
                text-decoration: none;
            }
        }
    }
}