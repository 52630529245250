.ms-webpart-chrome {

    .ms-webpart-chrome-title {
        border-bottom: 2px solid $accent;
        background: $primary-dark;
        display: block;
        margin: 0;
        padding: 12px;

        H2, H2 > A {
            margin: 0;
            font-size: 1.33em;
            line-height: 30px;
            font-weight: 600;
            color: $white;
        }
    }

    .ms-WPBody {
        padding: 14px;
        background: transparent;

        .bannerContainer & {
            padding: 0;
        }
    }

    .ms-WPBorder {
        border: 1px solid $border;
        padding: 14px;
        font-size: 1em;
        border-width: 0 1px 1px 1px;
        background: $white;
        
        .bannerContainer & {
            padding: 0;
        }
    }

    .ms-wpContentDivSpace {
        margin-bottom: 17px;
    }
}