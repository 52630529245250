
.ms-rteStyle-ButtonDefault  {
    -ms-name: "Button Default";
    -ms-element:"true";
    color: inherit;

}


.ms-rteStyle-CharacterSpacing {
    -ms-name: "character spacing .75";
    letter-spacing: -.75px;
    color: inherit;
}

.ms-rteStyle-lineheight1 {
    -ms-name: "lineheight-1";
    line-height: 1;
    display: block;
}

.ms-rteStyle-lineheight2 {
    -ms-name: "lineheight-1.15";
    line-height: 1.15;
    display: block;
}

.ms-rteStyle-Uppercase {
    -ms-name: "Text Uppercase";
    text-transform: uppercase;
}

.ms-rteStyle-Lowercase {
    -ms-name: "Text Lowercase";
    text-transform: lowercase;
}

.ms-rteStyle-Capitalize {
    -ms-name: "Text Capitalize";
    text-transform: capitalize;
}

.ms-rteStyle-SmallCaps {
    -ms-name: "Smallcaps";
    font-variant: small-caps;
}


.ms-rteStyle-SmallCaps-L1 {
    -ms-name: "Smallcaps L-1";
    font-variant: small-caps;
    line-height: 1;
    display: block;
}

.ms-rteStyle-SmallCaps-L2 {
    -ms-name: "Smallcaps L-1.15";
    font-variant: small-caps;
    line-height: 1.15;
    display: block;
}


/*======Upper Case +1.0(Line Spacing)+.75(Inter Character spacing)=====*/

.ms-rteStyle-Uppercase-L-1-Ch-Sp-075 {
    -ms-name: "U-Case L-1 CS-.75";
    text-transform: uppercase;
    letter-spacing: -.75px;
    line-height: 1;
    display: block;
}

/*======Upper Case +1.15(Line Spacing)+.75(Inter Character spacing) =====*/



.ms-rteStyle-Uppercase-L-2-Ch-Sp-075 {
    -ms-name: "U-Case L-1.15 CS-.75";
    text-transform: uppercase;
    letter-spacing: -.75px;
    line-height: 1.15;
    display: block;
}


/*======Lower Case + 1.0(Line Spacing)+.75(Inter Character spacing) =====*/

.ms-rteStyle-Lowercase-L-1-Ch-Sp-075 {
    -ms-name: "L-Case L-1 CS-.75";
    text-transform: lowercase;
    letter-spacing: -.75px;
    line-height: 1;
    display: block;
}


/*======Lower Case +1.15(Line Spacing)+.75(Inter Character spacing) =====*/

.ms-rteStyle-Lowercase-L-2-Ch-Sp-075 {
    -ms-name: "L-Case L-1.15 CS-.75";
    text-transform: lowercase;
    letter-spacing: -.75px;
    line-height: 1.15;
    display: block;
}



/*======Capitalized +1.0(Line Spacing)+.75(Inter Character spacing) =====*/


.ms-rteStyle-Capitalize-L-1-Ch-Sp-075 {
    -ms-name: "Cap L-1 CS-.75";
    text-transform: capitalize;
    letter-spacing: -.75px;
    line-height: 1;
    display: block;
}

/*======Capitalized +1.15(Line Spacing)+.75(Inter Character spacing) =====*/

.ms-rteStyle-Capitalize-L-2-Ch-Sp-075 {
    -ms-name: "Cap L-1.15 CS-.75";
    text-transform: capitalize;
    letter-spacing: -.75px;
    line-height: 1.15;
    display: block;
}

/*======Small Caps+1.0(Line Spacing)+.75(Inter Character spacing) =====*/


.ms-rteStyle-SmallCaps-L-1-Ch-Sp-075 {
    -ms-name: "Smallcaps L-1 CS-.75";
    font-variant: small-caps;
    letter-spacing: -.75px;
    line-height: 1;
    display: block;
}

/*======Small Caps+1.15(Line Spacing)+.75(Inter Character spacing) =====*/


.ms-rteStyle-SmallCaps-L-2-Ch-Sp-075 {
    -ms-name: "Smallcaps L-1.15 CS-.75";
    font-variant: small-caps;
    letter-spacing: -.75px;
    line-height: 1.15;
    display: block;
}





.ms-rtestate-field p, p.ms-rteElement-P {
    line-height: 1.4;
}




SPAN.ms-rteStyle-ButtonDefault A, 
SPAN.ms-rteStyle-ButtonDefault A:link, 
SPAN.ms-rteStyle-ButtonDefault A:visited, 
A SPAN.ms-rteStyle-ButtonDefault,
A:link SPAN.ms-rteStyle-ButtonDefault,
A:visited SPAN.ms-rteStyle-ButtonDefault {
    color: $primary-dark;
    background: $white;
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid $neutral-med;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.42857143;
    border-radius: 3px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-decoration: none !important;
}

SPAN.ms-rteStyle-ButtonDefault A:focus,
A:focus SPAN.ms-rteStyle-ButtonDefault  {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

SPAN.ms-rteStyle-ButtonDefault  A:hover,
A:hover SPAN.ms-rteStyle-ButtonDefault  {
    outline: 0;
    background-image: none;
    color: $black;
    border-color: $black;
}