
.global-header.fixed {
    width: calc(100% - 17px);

    @media (-webkit-min-device-pixel-ratio:0) and (max-width:1024px) {
            width: 100% !important;
    }

    @media screen and (-webkit-min-device-pixel-ratio:0) {
        width: calc(100% - 17px);
        
    }
}

.header-cagov-logo img {
    top: 4px;
    @media only screen and (max-width : 767px) {
        top: 5px;
    }
}

.utility-header {
    background: $primary;
    color: $white;
    ul li {
        padding: 0 0px 0 16px;
    }

    A {
        &:link, &:visited, &:hover, &:active, &:focus {
            color: #fff !important;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .settings-links {
        font-size: 0.78em;
        @media only screen and (max-width : 767px) {
            font-size: 0.67em
        }
    }

    .topSettingLinks {
        margin-top: 3px;
        position: relative;
    }
 
    .topNavLinks {
        margin-top: 7px;
        li {
            padding: 0 15px;
            display: inline;
        }
    }
    /*.topNavLinks a{color:#fff;font-size:0.78em;}
    .topNavLinks a:hover{color:#ffbf00;}*/

    .topSettingFont {
        font-size: 0.78em !important
    }

    .half {
        padding-left: 0;
        padding-top: 0
    }

}

// logo
.branding .header-organization-banner {
    img {
        max-height: inherit;
        -webkit-transition: height 0.3s !important;;
        transition: height 0.3s !important;
    }
    @media only screen and (max-width : 767px) {
        padding-right: 120px;
        line-height: 82px;
    }

    @media only screen and (min-width: 768px) and (max-width : 1068px) {
        A {
            width: 300px;
            max-width: 300px;
            height: 50px;
            position: relative;
            overflow: hidden;
            top: 16px;

            IMG {
                position: absolute;
                top: -10px;
                left: -82px;
                max-width: 333px;
                width: 333px;
            }
        }
    }

    @media only screen and (min-width: 768px) and (max-width : 820px) {
        A {
            width: 225px;
            max-width: 225px;

            IMG {
                max-width: 303px;
                width: 303px;
            }
        }
    }

    // compact
    .global-header.fixed.compact & {
        @media only screen and (min-width: 768px){
            A {
                width: 225px;
                max-width: 225px;
                height: 50px;
                position: relative;
                overflow: hidden;
                top: 0;

                IMG {
                    position: absolute;
                    top: -10px;
                    left: -82px;
                    max-width: 303px;
                    width: 303px;
                }
            }
        }
    }
}


.langTextContainer {
    float: right;
    font-size: 0.78em;
    
    .language {
        display: inline-block;
        padding-right: 20px;
        padding-top: 5px;
    }
    .textAccess {
        display: inline-block;
        border-left: 1px solid #fff;
        height: 40px;
        padding-left: 20px;
    }
}

.goog-logo-link, .goog-te-gadget-icon {
    display: none !important;
}

.goog-te-gadget-simple {
    background: transparent !important;
    border: 0 !important;
    padding: 0 !important;
    font-size: inherit !important;

    .goog-te-menu-value span {
        color: #fff !important;
        font-family: inherit;
        font-size: 0.7em !important;
    }    
}

.goog-te-gadget {
    font-size: 1em !important;
    color: transparent !important;
    .goog-te-combo {
        font-family: inherit;
        margin: 0 !important;
        padding: 2px 0;
        border: 0;
        background: #1D7EAF;
        color: #fff;
        font-family: "Source Sans Pro", sans-serif;
        font-size: 0.78em !important;
    }
}

#google_translate_element_mobile .goog-te-gadget-simple {
    border: 1px solid #f4f4f4 !important;
}


.gtransHeight {
    height: 20px;
    overflow: hidden;
    position: absolute;
    left: 49px;
    top: 4px;
    @media (min-width: 992px) and (max-width : 1280px) {
        left: 18px;
    }
    @media only screen and (max-width : 767px) {
        height: 29px;
        overflow: hidden;
        position: inherit;
        left: 0;
        top: 0px;
    }


}

