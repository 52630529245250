@charset "utf-8";
/* CSS Document - Custom styles that overwrite the template CSS code*/

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600');

@import 'partials/variables';
@import 'partials/sp-overrides';
@import 'partials/general';
@import 'partials/header';
@import 'partials/breadcrumb';
@import 'partials/navigation';
@import 'partials/searchbox';
@import 'partials/banner-images';
@import 'partials/alert';
@import 'partials/searchresults';
@import 'partials/footer';
@import 'partials/refactor';
@import 'partials/ribbonstyles';
@import 'partials/page-service';
@import 'partials/modals';
@import 'partials/webpart';