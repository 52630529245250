
.alertContainer {
    margin-top: -10px;


    .alert-banner {
        padding: 10px 0;
        .close {
            right: 0px;
        }
    }

    .alertContent {
        padding: 10px 15px 25px 15px;
    }

    .alert-severe {
        background: $accent-darker;
        
        .alert-level {
            background: #ffc478;
            padding: 4px;
            border-radius: 4px;
            margin-left: 0;
            margin-bottom: 5px;
        }

        .alert-text {
            font-size: 0.89em;
            color: #231f20;
            display: block;
        }

        .alert-link.btn-default {
            background: $white !important;
        }
    }

}


.alert-xs {
    padding: 5px;
    font-size: 0.9em;
}