
.bannerContainer {
    .customImgRes {
        width: 100% !important;
        max-width: 2560px;
        display: block;
        height: auto !important;
        margin: auto;
        
    }


    .bannerImageContainer {
        /* -webkit-box-shadow: 0px 9px 6px 0px rgba(0,0,0,0.55);
        -moz-box-shadow: 0px 9px 6px 0px rgba(0,0,0,0.55);
        box-shadow: 0px 9px 6px 0px rgba(0,0,0,0.55);*/
        position: relative;
        background: #555;
    }

    .bannerImageWelcomeTxt {
        /*-webkit-box-shadow: 0px 9px 6px 0px rgba(0,0,0,0.55);
        -moz-box-shadow: 0px 9px 6px 0px rgba(0,0,0,0.55);
        box-shadow: 0px 9px 6px 0px rgba(0,0,0,0.55);*/
        -webkit-box-shadow: 0px 11px 1px 0px rgba(0,0,0,0.55);
        -moz-box-shadow: 0px 11px 1px 0px rgba(0,0,0,0.55);
        box-shadow: 0px 11px 1px 0px rgba(0,0,0,0.55);
        position: absolute;
        background: rgba(85,85,85,0.80);
        bottom: 0;
        width: 100%
    }

    .bannerImageWelcomeTxtOuter {
        width: 81%;
        margin: auto;
        padding-bottom: 2px;
        padding-top: 2px;
        @media (min-width: 768px) and (max-width:991px) {
            padding-bottom: 8px;
        }
        .welcomeTxt {
            font-size: 1.33em;
            color: #fff;
            margin-left: 32px;
            font-weight: 600;
            line-height: 30px;
            @media (min-width: 768px) and (max-width:991px) {
                font-size: 1.11em
            }
        }

        .deptNameTxt {
            font-size: 3.33em;
            color: #fff;
            line-height: 72px;
            font-weight: 600;
            @media (min-width: 768px) and (max-width:991px) {
                font-size: 2em;
                line-height: 35px;
            }
        }
    }

    @media only screen and (max-width : 767px) {
        .customImgResMobile {
            width: 100%;
            max-width: 1024px;
            display: block;
            height: auto;
        }

        .bannerText {
            font-size: 1.11em;
            width: 95%;
            margin: 8px auto 48px auto
        }
    }
}