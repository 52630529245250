

#myModal {

    padding-left: 0 !important;

    .modal-header {
        &.bg-primary h4.modal-title {
            color: $white !important;
        }

        .close {
            color: $white;
            opacity: 1;
            font-size: 2.1em
        }
    }

    .container {
        width: auto;
    }

    .modal-dialog {
        width: 100%;

        
        @media only screen and (min-width : 1200px) {

            width: 1170px;
        }

        @media (max-width: 767px){
            margin: 0;
            padding: 10px;
        }

    }

}

