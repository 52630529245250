.ms-srch-result #ResultFooter {
    display: none;
}

.layout-searchResult {
    padding-top: 20px;

    .ms-srch-hover-outerContainer {
        left: auto !important;
        right: 0px;
        top: 0 !important;
    }

    .ms-srch-item {
        width: auto;
        margin-top: 0px;
        margin-bottom: 0px;
        padding-top: 20px;
        padding-bottom: 20px;
        overflow: visible;
        position: relative;
    }

    h3.ms-srch-ellipsis {
        font-size: 1em;
        margin: 0px;
    }

    .ms-srch-item-path {
        color: #b0b1b6;
    }

    .ms-srch-item:hover, .ms-srch-resultHover:hover, .edisc-SearchResultItemContainer:hover {
        background-color: #f1f1f1;
    }

    .ms-srch-item {
        border-bottom: 1px solid #e4e4e4;
    }

    .ms-srch-hover-arrowBorder, .ms-srch-hover-arrow {
        top: 30px !important;
    }

    .ms-ref-refinername {
        width: 100% !important;
        &:link, &:visited {
            font-weight: bold;
        }
    }

    .ms-ref-uparrow {
        display: inline-block;
    }

    .ms-ref-refiner {
        border-bottom: 0px solid #e4e4e4;
        margin-bottom: 15px;
        #Container {
            border-bottom: 1px solid #e4e4e4;
        }
    }

    .ms-ref-unsel-toggle.ms-commandLink {
        width: 100%;
        height: 100%;
        // background: #e4e4e4;
        // text-align: center;
        // font-weight: bold;
        // color: #b0b1b5;
        color: $link;
        margin-top: 15px;
    }

    .ms-srch-result {
        margin-top: 0px;
    }

    .slider_extent_label_section,
    .handle_label, 
    .ms-srch-resultFooter, .ms-srch-resultFooter a,
    .ms-srch-result #PagingLink a {
        color: $textcolor;
    }
}

/*Search Page*/
.selAll {
    display: inline;
    margin-right: 10px;
}

.itmSel {
    display: inline;
}
