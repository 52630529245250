
.main-navigation {
    margin-right: 0;
}


.navigation-search {
    border-bottom: 5px solid $accent;
    position: relative;
}

.iconPrograms, .iconAssistance, .iconCommunity, .iconLookingFor, .iconSearch {
    list-style:none;
    display: inline-block;
    position: static;
    @media (max-width: 767px){
        position: relative;
        margin-top: 1px;
        display: block;
    }
    @media (min-width: 768px){
        padding: 0;
        margin-top: 0;
        background: none;
        width: auto;
        display: table-cell;
        display: block;
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;

        &:hover:after {
            background: #046B99;
            content: " ";
            height: 5px;
            display: block;
            margin: 0 auto;
        }
    }
}

.iconPrograms {
    background: url(../images/iconPrograms.png) no-repeat;
    width: 32px;
    height: 32px;
}

.iconAssistance {
    background: url(../images/iconAssistance.png) no-repeat;
    width: 32px;
    height: 32px;
}

.iconCommunity {
    background: url(../images/iconCommunity.png) no-repeat;
    width: 35px;
    height: 32px;
}

.iconLookingFor {
    background: url(../images/iconLookingFor.png) no-repeat;
    width: 30px;
    height: 32px;
}

.iconSearch {
    background: url(../images/iconSearch.png) no-repeat;
    width: 25px;
    height: 32px;
}

.top-level-nav {
    .nav-item {
        & > a {
            color: #535353;
            font-weight: 600;
            padding: 14px 0;
            font-size: 1em;
            line-height: 24px;
            @media (min-width: 768px) and (max-width:991px) {
                font-size: 0.89em;
                outline: none;

                &.lookingArrow::after {
                    content: " ";
                    background: url(../images/bottomArrow.png) bottom center no-repeat;
                    width: 16px;
                    height: 11px;
                    margin: auto;
                    display: block
                }

                &::after {
                    content: " ";
                    background: transparent;
                    width: 16px;
                    height: 11px;
                    margin: auto;
                    display: block
                }
            }
           
        }
        & > a:hover {
            color: $primary-dark;

            span.iconPrograms {
                background-position: -33px 0
            }

            span.iconAssistance {
                background-position: -32px 0
            }

            span.iconCommunity {
                background-position: -36px 0
            }

            span.iconLookingFor {
                background-position: -31px 0
            }

            span.iconSearch {
                background-position: -26px 0
            }

        }

        &:hover::after, &:focus::after {
            background: $primary-dark
        }
    }
}



.sub-nav {
    background: rgba(255,255,255,0.9);
    border-bottom: 0;
    border-left: 1px solid $border;
    border-right: 1px solid $border;
}

.second-level-nav > li {
    border-right: 1px solid $border;
    border-bottom: 1px solid $border;
        
    &:hover {
        background: transparent;
    }
}

.second-level-link {
    &:link, &:visited {
        color: $black !important;
        font-size: 1em !important;
        background: transparent !important;
    }
    
    &:hover {
        color: $primary-dark !important;
        background: transparent !important;
    }

    @media (min-width: 768px) and (max-width:991px) {
        font-size: 0.89em !important;
    }

    @media only screen and (max-width : 767px) {
        padding: 10px 15px 10px 12px !important;
    }

}


.unit1 {
    height: auto;
}

@media (min-width: 768px) and (max-width:991px) {
    #navigation ul {
        margin-bottom: 0 !important
    }
}

@media only screen and (max-width : 767px) {
    #nav_home_container {
        margin: 0 10px;
        color: #7bbbde
    }

}



#mainNav, #utilityLinksMobile {
    
    .top-level-nav {
        list-style: none;
        margin: 0;
        padding: 0;

        @media (min-width: 768px) {
            display: table;
            table-layout: auto;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            width: 100%;
            min-height: 100px;

            // compact
            .global-header.fixed.compact & {
                min-height:56px;
            }
        }
        & > LI {
            list-style:none;
            display: inline-block;
            position: static;
            @media (max-width: 767px){
                position: relative;
                margin-top: 1px;
                display: block;
            }
            @media (min-width: 768px){
                padding: 0;
                margin-top: 0;
                background: none;
                width: auto;
                display: table-cell;
                display: block;
                min-height: 100px;
                -webkit-box-flex: 1;
                -moz-box-flex: 1;
                -webkit-flex: 1 1 auto;
                -ms-flex: 1 1 auto;
                flex: 1 1 auto;

                // compact
                .global-header.fixed.compact & {
                    min-height:56px;
                }

                &:hover:after {
                    background: #046B99;
                    content: " ";
                    height: 5px;
                    display: block;
                    margin: 0 auto;
                }
            }

            & > A {
                display: block;
                margin: 0 auto;
                text-decoration: none;
                color: #535353;
                font-weight: 600;
                padding: 14px 0;
                font-size: 1em;
                line-height: 24px;
                white-space: nowrap;
            
                @media only screen and (max-width : 767px) {
                    border-bottom: 1px solid #E1F2F7;
                }

                @media (min-width: 768px){
                    text-align: center;
                    min-height: 95px;

                    // compact
                    .global-header.fixed.compact & {
                        min-height:56px;
                        padding: 6px;
                    }
                }

                &:hover {
                    color: #046B99;
                
                }

                &:before { // icon
                    display: block;
                    content: "";
                    width: 32px;
                    height: 32px;
                    margin: 0 auto;
                    @media (max-width: 767px){
                        display: inline-block;
                        margin: -9px 10px -8px 10px;
                    }

                    .global-header.fixed.compact & {
                        display: none;
                    }
                }

                
            } // A
            
            &.has-sub {
                @media (min-width: 768px){
                    &:hover, &.open {
                        UL {
                            display: flex;
                        } 
                    }
                    & > A {
                    
                        &:after {
                            content: " ";
                            background: url(../images/bottomArrow.png) bottom center no-repeat;
                            width: 16px;
                            height: 11px;
                            margin: auto;
                            display: block
                        }

                    }
                }
            }

            .toggle-submenu {
                position: absolute;
                top: 0;
                right: 0;
                font-size: 24px;
                font-size: 1.5rem;
                line-height: 50px;
                width: 60px;
                text-align: center;
                cursor: pointer;
                color: #777777;
                display: block;
                padding: 0;
                background: none;
                border: 0;
                
                @media (min-width: 768px){
                    display: none;
                }

                &.closed .ca-gov-icon-menu-toggle-open {
                    display: none;
                }
                &.open .ca-gov-icon-menu-toggle-closed {
                    display: none;
                }

            }
        }
    }

    // icons
    &.nav-menu > .top-level-nav > LI:nth-child(1) > A {
        &:before {
            background: url(../images/iconPrograms.png) no-repeat;
        }
        &:hover:before {
            background-position: -33px 0;
        }
    }

    &.nav-menu > .top-level-nav > LI:nth-child(2) > A {
        &:before {
            background: url(../images/iconAssistance.png) no-repeat;
        }
        &:hover:before {
            background-position: -32px 0;
        }
    }

    &.nav-menu > .top-level-nav > LI:nth-child(3) > A {
        &:before {
            background: url(../images/iconCommunity.png) no-repeat;
            width: 35px;
        }
        &:hover:before {
            background-position: -36px 0;
        }
    }

    &.nav-menu > .top-level-nav > LI:nth-child(4) > A {
        &:before {
            background: url(../images/iconLookingFor.png) no-repeat;
            width: 30px;
        }
        &:hover:before {
            background-position: -31px 0;
        }
    }

    // &.nav-menu > .top-level-nav > LI:nth-child(5) {
    //     display:none;
    // }

    &.nav-menu > .top-level-nav > LI:nth-child(5) > A {
        &:before {
            background: url(../images/iconSearch.png) no-repeat;
            width: 25px;
        }
        &:hover:before {
            background-position: -26px 0;
        }
    }

    .top-level-nav UL { // dropdown
        background: rgba(255,255,255,0.9);
        border-left: 1px solid $border;
        border-right: 1px solid $border;
        margin: 0;
        padding: 0;
        list-style: none;

        @media (max-width: 767px){
            overflow: hidden;
            max-height: 0;
            transition: all .4s;
            border-bottom: 1px solid $border;

            LI {
                border-top: 1px solid $border;
                &:hover {
                    background: #fff;
                }
                A {
                    padding: 10px 15px 10px 15px;
                    display: block;
                    color: #000 !important;
                    font-size: 1em !important;
                    text-decoration: none;

                    &:hover {
                        color: $primary-dark !important;
                    }
                }

            }
        }

        @media (min-width: 768px){
            position: absolute;
            display: none;
            max-height: 700px;
            left: -30px;
            top: 100%;
            width: 100%;
            flex-wrap: wrap;
            border-bottom: 1px solid $border;
        
            LI {
                width: 33.333333%;
                flex-grow: 1;

                A {
                    display: block;
                    padding: 15px;
                    color: #000 !important;
                    border-style: solid;
                    border-color: $border;
                    border-width: 0;
                    border-right-width: 1px;
                    border-top-width: 1px;
                    text-decoration: none;

                    &:hover {
                        color: $primary-dark !important;
                    }
                }

                &:nth-child(1) A, &:nth-child(2) A, &:nth-child(3) A {
                    border-top-width: 0;
                }
                &:nth-child(3n) A {
                    border-right-width: 0;
                }
            }
        }
    }

    // mobile open dropdown
    .top-level-nav > LI.open {
        
        @media (max-width: 767px){
            UL {
                max-height: 900px;
            }
        }
        
    }
}