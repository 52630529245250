
body {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    line-height: normal;
    background: #fff;
}

a {
    &:link, &:visited {
        color: $link;
    }
    
}


// numbered list styling inside of rich text editors
.ms-rtestate-field {
    OL {
        OL {
            list-style-type: upper-alpha;

            OL {
                list-style-type: lower-roman;

                OL {
                    list-style-type: lower-alpha;
                }
            }
        }
    }
}

.clear {
    clear: both
}

.cursorPointer {
    cursor: pointer
}

.titleInnerPage {
    color: $primary-med;
    font-size: 2.78em;
    font-weight: bold;
    padding: 10px 0 26px 0;
}

.section {
    padding-bottom: 10px;
}

.sepration {
    clear: both;
}

.texterror {
    color: #ff0000;
}

.commonTextContainer {
    margin-bottom: 30px;
}


.main-content {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
}

.well-xs {
    padding: 5px;
}



@media (min-width: 768px) and (max-width:991px) {
    .LeftDivider {
        border-left: 1px solid #e4e4e4;
    }
    
}

@media (min-width: 992px) and (max-width : 1280px) {
    .paddingTabDevice0 {
        padding-left: 0;
        padding-right: 0;
    }

    .padding-xssm0 {
        padding-left: 0
    }
}

@media only screen and (max-width : 767px) {
    .padding0-xs {
        padding: 0
    }

    .paddingLeft0 {
        padding-left: 0;
    }

    button {
        min-width: 1px;
    }

    .return-top.is-visible {
        display: block;
    }

    .marginTop4-xs {
        margin-top: 4px;
    }

    .LeftDivider {
        border-left: 1px solid #e4e4e4;
    }

    .main-content {
        padding-top: 140px !important;
    }
}